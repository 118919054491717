import { InitOptions } from 'i18next';

import { languagesDefault, languagesSupported } from './resource';

//
//

export const i18nOptions = {
  supportedLngs: languagesSupported,
  fallbackLng: languagesDefault,
  defaultNS: 'common',
  react: { useSuspense: false },
} as Omit<InitOptions, 'react' | 'detection'>;
