import { RemixBrowser } from '@remix-run/react';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { getInitialNamespaces } from 'remix-i18next/client';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { ClientCacheProvider } from '~/zidmui/libraries/emotion/client-cache';
import { themeParcel } from '~/zidmui/libraries/mui';

import { i18nOptions } from '~/libraries/i18next/options';
import '~/libraries/sentry/sentry.client';

//
//

const i18nHash: Record<string, Record<string, string>> = {};

const hydrate = async () => {
  if (!i18next.isInitialized)
    await i18next
      .use(initReactI18next) // Tell i18next to use the react-i18next plugin
      .use(LanguageDetector) // Setup a client-side language detector
      .use(Backend) // Setup your backend
      .init({
        ...i18nOptions, // spread the configuration
        // This function detects the namespaces your routes rendered while SSR use
        ns: getInitialNamespaces(),
        backend: {
          loadPath: (lng: string, ns: string) =>
            `/locales/${lng}/${ns}.json?${i18nHash?.[lng]?.[ns] || 'FALLBACK_I18N_HASH'}`,
        },
        detection: {
          order: ['localStorage', 'htmlTag', 'navigator'],
          lookupSessionStorage: '_lang',
          caches: [],
        },
        // Disabling suspense is recommended
        react: { useSuspense: false },
      });

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <ClientCacheProvider>
          <StrictMode>
            <ThemeProvider theme={themeParcel}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <RemixBrowser />
            </ThemeProvider>
          </StrictMode>
        </ClientCacheProvider>
      </I18nextProvider>,
    );
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1);
}
